import React, { useState, useEffect, useRef } from 'react';
import { Search } from 'lucide-react';
import { useRecoilState } from 'recoil';
import { stateAtom, districtAtom, blockAtom } from '../store/locationStore';

const KYLLocationSearchBar = ({ statesData, onLocationSelect }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef(null);
    
    // Recoil state
    const [state, setState] = useRecoilState(stateAtom);
    const [district, setDistrict] = useRecoilState(districtAtom);
    const [block, setBlock] = useRecoilState(blockAtom);

    // Process statesData to create a flat array of searchable locations
    const getAllLocations = () => {
        if (!statesData) return [];
        
        const locations = [];
        
        statesData.forEach(state => {
            // Add state
            locations.push({
                id: `state-${state.state_id}`,
                name: state.label,
                type: 'state',
                data: state
            });
            
            // Add districts
            state.district?.forEach(district => {
                locations.push({
                    id: `district-${district.district_id}`,
                    name: `${district.label}, ${state.label}`,
                    type: 'district',
                    data: {
                        state: state,
                        district: district
                    }
                });
                
                // Add blocks
                district.blocks?.forEach(block => {
                    locations.push({
                        id: `block-${block.block_id}`,
                        name: `${block.label}, ${district.label}, ${state.label}`,
                        type: 'block',
                        data: {
                            state: state,
                            district: district,
                            block: block
                        }
                    });
                });
            });
        });
        
        return locations;
    };

    // Filter locations based on search query
    const getFilteredLocations = (query) => {
        if (!query) return [];
        
        const locations = getAllLocations();
        const normalizedQuery = query.toLowerCase();
        
        return locations
            .filter(location => 
                location.name.toLowerCase().includes(normalizedQuery)
            )
            .slice(0, 5); // Limit to 5 suggestions
    };

    // Handle search input change
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        setSuggestions(getFilteredLocations(value));
        setIsOpen(true);
    };

    // Update location state based on selection
    const updateLocationState = (location) => {
        switch (location.type) {
            case 'state':
                setState(location.data);
                setDistrict(null);
                setBlock(null);
                break;
            case 'district':
                setState(location.data.state);
                setDistrict(location.data.district);
                setBlock(null);
                break;
            case 'block':
                setState(location.data.state);
                setDistrict(location.data.district);
                setBlock(location.data.block);
                break;
            default:
                break;
        }

        // Call the optional callback if provided
        if (onLocationSelect) {
            onLocationSelect(location);
        }
    };

    // Handle location selection
    const handleLocationSelect = (location) => {
        setSearchQuery(location.name.split(',')[0]); // Only show the immediate location name
        setIsOpen(false);
        updateLocationState(location);
    };

    useEffect(() => {
        if (block) {
            setSearchQuery(block.label);
            // Get filtered locations based on current block name
            setSuggestions(getFilteredLocations(block.label));
            setIsOpen(false);
        } else if (district) {
            setSearchQuery(district.label);
            setSuggestions(getFilteredLocations(district.label));
            setIsOpen(false);
        } else if (state) {
            setSearchQuery(state.label);
            setSuggestions(getFilteredLocations(state.label));
            setIsOpen(false);
        } else {
            setSearchQuery('');
            setSuggestions([]);
            setIsOpen(false);
        }
    }, [block, district, state]);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div ref={wrapperRef} className="relative w-[300px]">
            <div className="relative">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onFocus={() => setIsOpen(true)}
                    placeholder="Search a particular location"
                    className="w-full px-4 py-2.5 pr-10 rounded-md text-sm focus:outline-none border border-gray-200 bg-white shadow-sm"
                />
                <div className="absolute right-3 top-1/2 -translate-y-1/2">
                    <Search className="w-4 h-4 text-gray-400" />
                </div>
            </div>

            {isOpen && suggestions.length > 0 && (
                <div className="absolute top-full left-0 right-0 mt-1 bg-white rounded-md shadow-lg border border-gray-200 max-h-[300px] overflow-y-auto z-50">
                    {suggestions.map((location) => (
                        <button
                            key={location.id}
                            onClick={() => handleLocationSelect(location)}
                            className="w-full px-4 py-2 text-left hover:bg-gray-50 border-b border-gray-100 last:border-0"
                        >
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-700 font-medium">
                                    {location.name.split(',')[0]}
                                </span>
                                {location.type !== 'state' && (
                                    <span className="text-xs text-gray-400">
                                        {location.name.split(',').slice(1).join(',')}
                                    </span>
                                )}
                            </div>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default KYLLocationSearchBar;