import React from 'react';
import KYLIndicatorFilter from './kyl_indicatorFilter';

const KYLLeftSidebar = ({
    indicatorType,
    setIndicatorType,
    filterSelections,
    setFilterSelections,
    getAllFilterTypes,
    getAllFilters,
    handleFilterSelection,
    state,
    district,
    block // New props
}) => {
    const combinedSelectedValues = {
        ...filterSelections.selectedMWSValues,
        ...filterSelections.selectedVillageValues
    };

    // Check if filters should be enabled
    const areFiltersEnabled = state && district && block;

    return (
        <div className="w-[320px] bg-white rounded-lg border border-gray-100 p-4">
            <div className="space-y-2">
                <button
                    className="w-full py-2 px-2 text-indigo-600 bg-indigo-50 rounded-lg text-sm font-medium text-left"
                    disabled={!areFiltersEnabled} // Disable based on conditions
                >
                    Browse locations based on indicators
                </button>

                <div className="flex flex-wrap gap-2 pt-2">
                    {getAllFilterTypes().map((category) => (
                        <button
                            key={category}
                            className={`px-3 py-1.5 rounded-md text-sm border transition-colors
                                ${indicatorType === category
                                    ? 'bg-indigo-50 text-indigo-600 border-indigo-200'
                                    : 'text-gray-600 border-gray-200 hover:bg-gray-50'}
                            `}
                            onClick={() => setIndicatorType(category)}
                            disabled={!areFiltersEnabled} // Disable based on conditions
                        >
                            {category}
                        </button>
                    ))}
                </div>

                {indicatorType && (
                    <div className="flex justify-end">
                        <button
                            onClick={() => {
                                // Clear all filters for both MWS and Village
                                setFilterSelections((prev) => ({
                                    ...prev,
                                    selectedMWSValues: {},
                                    selectedVillageValues: {}
                                }));
                            }}
                            className="text-xs text-indigo-600 hover:text-indigo-700 underline"
                            disabled={!areFiltersEnabled} // Disable based on conditions
                        >
                            Clear all
                        </button>
                    </div>
                )}

                {indicatorType && (
                    <div className="bg-gray-50 rounded-lg p-4 mt-2 relative">
                        <button
                            onClick={() => setIndicatorType(null)}
                            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                        >
                            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M18 6L6 18M6 6l12 12"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                />
                            </svg>
                        </button>

                        <div
                            className="overflow-y-auto custom-scrollbar"
                            style={{ maxHeight: 'calc(90vh - 280px)' }}
                        >
                            <div className="space-y-6">
                                {getAllFilters()
                                    .filter((filter) => filter.category === indicatorType)
                                    .map((filter) => (
                                        <div key={filter.name} className="space-y-3">
                                            <KYLIndicatorFilter
                                                filter={{
                                                    ...filter,
                                                    selectedValue: combinedSelectedValues[filter.name]?.[0]
                                                }}
                                                onFilterChange={handleFilterSelection}
                                                isDisabled={!areFiltersEnabled} // Pass disabled state
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default KYLLeftSidebar;
