import Sidebar from "../components/sidebar"
import MapArea from "../components/mapArea"
const LE_page = () => {
    return(
        <div>
            <Sidebar/>
            <MapArea/>
        </div>
    )
}

export default LE_page;