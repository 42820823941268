// src/components/kyl_mapContainer.jsx
import KYLLocationSearchBar from './kyl_location';
import { useState } from 'react';
import YearSlider from './yearSlider';

// Separate Layer Controls component positioned at top left
const LayerControls = ({ showMWS, setShowMWS, showVillages, setShowVillages, areMWSLayersAvailable, areVillageLayersAvailable }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className="absolute top-6 left-6 z-10">
            {/* Burger Menu Button */}
            <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="bg-white p-2 rounded-lg shadow-md hover:bg-gray-50"
                aria-label="Toggle layer controls"
            >
                <svg className="w-5 h-5 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>

            {/* Layer Controls Menu */}
            {isMenuOpen && (
                <div className="absolute top-12 left-0 bg-white p-3 rounded-lg shadow-md space-y-3 min-w-[200px]">
                    <div className={`flex items-center gap-2 ${!areMWSLayersAvailable ? 'opacity-50 cursor-not-allowed' : ''}`}>
                        <input
                            type="checkbox"
                            id="show-mws"
                            checked={showMWS}
                            onChange={(e) => setShowMWS(e.target.checked)}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            disabled={!areMWSLayersAvailable}
                        />
                        <label
                            htmlFor="show-mws"
                            className={`text-sm ${areMWSLayersAvailable ? 'text-gray-700' : 'text-gray-400'}`}
                        >
                            Show MWS Layers
                        </label>
                    </div>

                    <div className={`flex items-center gap-2 ${!areVillageLayersAvailable ? 'opacity-50 cursor-not-allowed' : ''}`}>
                        <input
                            type="checkbox"
                            id="show-villages"
                            checked={showVillages}
                            onChange={(e) => setShowVillages(e.target.checked)}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            disabled={!areVillageLayersAvailable}
                        />
                        <label
                            htmlFor="show-villages"
                            className={`text-sm ${areVillageLayersAvailable ? 'text-gray-700' : 'text-gray-400'}`}
                        >
                            Show Village Boundaries
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
};

const MapZoomControls = ({ mapRef }) => {
    const handleZoomIn = () => {
        if (!mapRef.current) return;
        const view = mapRef.current.getView();
        const currentZoom = view.getZoom();
        view.animate({
            zoom: currentZoom + 0.5,
            duration: 500,
            easing: (t) => {
                return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
            }
        });
    };

    const handleZoomOut = () => {
        if (!mapRef.current) return;
        const view = mapRef.current.getView();
        const currentZoom = view.getZoom();
        view.animate({
            zoom: currentZoom - 0.5,
            duration: 500,
            easing: (t) => {
                return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
            }
        });
    };

    return (
        <div className="absolute right-6 bottom-6 z-10 flex flex-col gap-2">
            <button
                onClick={handleZoomIn}
                className="bg-white p-2 rounded-lg shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all duration-200"
                aria-label="Zoom in"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                </svg>
            </button>
            <button
                onClick={handleZoomOut}
                className="bg-white p-2 rounded-lg shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all duration-200"
                aria-label="Zoom out"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M18 12H6"
                    />
                </svg>
            </button>
        </div>
    );
};

const KYLMapContainer = ({
    isLoading,
    statesData,
    mapElement,
    onLocationSelect,
    showMWS,
    setShowMWS,
    showVillages,
    setShowVillages,
    mwsLayerRef,
    boundaryLayerRef,
    mapRef,
}) => {
    const areMWSLayersAvailable = mwsLayerRef?.current !== null;
    const areVillageLayersAvailable = boundaryLayerRef?.current !== null;

    return (
        <div className="flex-1 bg-[#F8F7FF] rounded-lg border border-gray-100 relative overflow-hidden">
          {isLoading && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">
              <div className="w-10 h-10 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
            </div>
          )}
      
          <LayerControls
            showMWS={showMWS}
            setShowMWS={setShowMWS}
            showVillages={showVillages}
            setShowVillages={setShowVillages}
            areMWSLayersAvailable={areMWSLayersAvailable}
            areVillageLayersAvailable={areVillageLayersAvailable}
          />
      
          <MapZoomControls mapRef={mapRef} />
      
          {/* Search Bar */}
          <div className="absolute top-6 left-1/2 transform -translate-x-1/2 z-10">
            <KYLLocationSearchBar
              statesData={statesData}
              onLocationSelect={onLocationSelect}
            />
          </div>
      
          {/* Centered YearSlider with max-width and padding */}
          {/* <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 z-10 w-full max-w-xl px-4">
            <YearSlider />
          </div> */}
      
          <div className="h-full" ref={mapElement} />
        </div>
    );
};

export default KYLMapContainer;